import React from "react";
import "../styles/Landing.css";
import kidsImg from "../Assets/imgs/character.webp";
import logo from "../Assets/imgs/logo.webp";
import mainImg from "../Assets/imgs/main-text.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};
const Landing = (props: Props) => {
  return (
    <>
      <div className="gameboxkids">
        <div className="main-content">
          <div className="logo">
            <img src={logo} alt="logo" width={250} height={60} />
          </div>
          <div className="mainImg">
            <img src={mainImg} alt="mainimg" width={375} height={205} />
          </div>
          <div className="onedayfreetrial">
            1-day <span> Free trial</span>
          </div>
          <div className="price">
            Rs.10/day <span> (inclusive Tax)</span>
          </div>
          <div>
            {props.showError && <p className="error">{props.errorMsg}</p>}
            {props.phoneNo && (
              <>
                {props.showPlay ? (
                  <>
                    {props.confirmLoader ? (
                      <button className="disable-btn playbtn">Confirm</button>
                    ) : (
                      <button
                        className="playbtn"
                        onClick={() => props.onSubscribePressed()}
                      >
                        Confirm
                      </button>
                    )}
                  </>
                ) : (
                  <button className="playbtn" onClick={props.onHandlePlay}>
                    Play
                  </button>
                )}
              </>
            )}
            <img src={kidsImg} alt="kid" width={375} height={355} />
          </div>
          <div className="termstext">
            <p>
              I agree for daily recurrent charges deduction from mobile account.{" "}
              <br /> Free trial is for new users only.{" "}
              <a href="/terms" target="__blank">
                Terms & Conditions
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
